<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/logo/Cyberbank konecta logo.svg"
                alt="login"
                class="mx-auto"
                width="60%"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{ lang.accountSetup.title[languageSelected] }}
                  </h4>
                  <p>{{ lang.accountSetup.description[languageSelected] }}</p>
                </div>

                <Languages />

                <vs-input
                  type="text"
                  :label-placeholder="
                    lang.accountSetup.form.companyName[languageSelected]
                  "
                  icon="icon icon-box"
                  icon-pack="feather"
                  v-model="companyName"
                  class="w-full mb-6 mt-6"
                  :disabled="sending"
                />
                <vs-input
                  type="text"
                  :label-placeholder="
                    lang.accountSetup.form.userName[languageSelected]
                  "
                  icon="icon icon-user"
                  icon-pack="feather"
                  v-model="userName"
                  class="w-full mb-6"
                  :disabled="sending"
                  :danger="userNameError !== null"
                  :dangerText="userNameError"
                />

                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.accountSetup.form.password1[languageSelected]
                  "
                  v-model="newPassword"
                  class="w-full mb-6"
                  icon-pack="feather"
                  icon="icon icon-lock"
                  :danger="newPasswordError !== null"
                  :dangerText="newPasswordError"
                  :disabled="sending"
                />
                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.accountSetup.form.password2[languageSelected]
                  "
                  v-model="repeatNewPassword"
                  class="w-full mb-base"
                  icon-pack="feather"
                  icon="icon icon-lock"
                  :danger="repeatNewPasswordError !== null"
                  :dangerText="repeatNewPasswordError"
                  :disabled="sending"
                />

                <vs-button
                  :disabled="
                      !companyName.trim() ||
                      !userName.trim() ||
                      !newPassword.trim() ||
                      newPassword !== repeatNewPassword ||
                      sending
                  "
                  class=" px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  @click="setup"
                >
                  {{ lang.accountSetup.login[languageSelected] }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import konecta from '@/services/konecta'
import { themeConfig, themeColors } from '../../../themeConfig'

import { sha256 } from 'js-sha256'
import Languages from '../home/components/Languages.vue'

const ROUTES_BY_ROL = {
  company_superadmin: '/bot-maker',
  company_editor: '/bot-maker',
  company_agent: '/attention-requests',
  company_supervisor: '/metrics',
  company_roles_manager: '/users',
  default: '/'
}

export default {
  name: 'AccountSetup',
  components: {
    Languages,
  },
  data() {
    return {
      companyName: '',
      userName: '',
      userNameError: null,
      newPassword: '',
      repeatNewPassword: '',
      sending: false
    }
  },
  watch: {
    userName() {
      this.userNameError = null
    },
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    newPasswordError() {
      if (
        this.newPassword.trim() // para que no tire error desde el principio
        && !this.validPassword(this.newPassword)
      ) {
        return this.lang.accountSetup.form.error
          .newPassword_invalid[this.languageSelected]
      }
      return null
    },
    repeatNewPasswordError() {
      if (
        this.newPassword !== '' &&
        this.repeatNewPassword !== '' &&
        this.newPassword !== this.repeatNewPassword
      ) {
        return this.lang.accountSetup.form.error
          .newPassword_neq_repeatNewPassword[this.languageSelected]
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'updateAndSaveSession',
      'setCompanyLogo',
      'updateTheme',
      'updateLoginMethod'
    ]),
    ...mapMutations([
      'UPDATE_THEME_COLORS',
      'UPDATE_LANGUAGE',
      'UPDATE_SIDEBAR_ITEMS_MIN'
    ]),
    validPassword(password) {
      return password
        && password.trim().length >= 8
    },
    redirectUserByRol(userData) {
      if (userData.redirect) {
        return this.$router.push(userData.redirect)
      }
      const rol = userData.roles && userData.roles[0]
      const userSection =
        rol && ROUTES_BY_ROL[rol]
          ? ROUTES_BY_ROL[rol]
          : ROUTES_BY_ROL['default']
      this.$router.push(userSection)
    },
    toggleClassInBody(className) {
      if (className == 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className == 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
      }
    },

    updateCompanyTheme(companyTheme) {
      if (companyTheme.companyLogo) {
        this.setCompanyLogo(companyTheme.companyLogo)
      }
      if (companyTheme.name) {
        this.updateTheme(companyTheme.name)
        this.toggleClassInBody(companyTheme.name)
      }
      if (companyTheme.colors) {
        this.UPDATE_THEME_COLORS(companyTheme.colors)
        this.$vs.theme(companyTheme.colors)
      }
    },
    updateCompanyLogin(method) {
      this.updateLoginMethod(method)
    },
    responseToast(title, text, color) {
      this.$vs.notify({
        title,
        text,
        color
      })
    },
    goBack() {
      this.userName = ''
      this.enterpass = false
      this.password = ''
      this.domains = []
      this.selectedDomain = ''
    },
    setDefaultTheme() {
      this.updateTheme(themeConfig.theme)
      this.$vs.theme(themeColors)
    },
    setup() {
      this.sending = true
      konecta
        .post('/company/account-setup', {
          token: this.$route.params.token,
          lang: this.languageSelected,
          companyName: this.companyName,
          userName: this.userName,
          newPassword: sha256(this.newPassword)
        })
        .then(response => {
          this.response = response
          this.sending = false
          //asignando respuesta a la vista
          if (this.response.status == 200) {
            const companyTheme = this.response.data.theme
            if (companyTheme) {
              this.updateCompanyTheme(companyTheme)
            }
            const companyLanguage = this.response.data.lang
            if (companyLanguage) {
              // moment, store
              this.$moment.locale(companyLanguage)
              this.UPDATE_LANGUAGE(companyLanguage)
            }

            const authMethod = this.response.data.authMethod
            if (authMethod) {
              this.updateCompanyLogin(authMethod)
            }

            this.updateAndSaveSession(this.response.data)
            this.$log.info('this session', this.$store.state.session)
            this.$log.info('local storage session', localStorage.session)
            this.redirectUserByRol(this.response.data)
            this.UPDATE_SIDEBAR_ITEMS_MIN(false)
          } else if (this.response.status == 400) {
            this.responseToast(
              'Error',
              this.lang.accountSetup.form.error.generic[
                this.languageSelected
              ],
              'warning'
            )
          }
        })
        .catch(err => {
          this.sending = false;
          console.error(err);
          if (
            err
            && err.response
            && err.response.status
            && err.response.status === 400
            && err.response.data
            && err.response.data[0]
            && err.response.data[0].message
            && err.response.data[0].message === 'already in use'
          ) {
            this.userNameError = this.lang.accountSetup.form.error.userNameAlreadyInUse[
              this.languageSelected
            ]
          } else {
            this.responseToast(
              'Error',
              this.lang.accountSetup.form.error.generic[
                this.languageSelected
              ],
              'warning'
            )
          }
        })
    }
  },
  mounted() {
    this.$vs.loading.close('body > .con-vs-loading')
  }
}
</script>
